import 'dayjs/locale/pt-br'

import _dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { setIn } from 'final-form'
import IMask from 'imask'
import isUUID from 'is-uuid'
import * as op from 'object-path'
import { toast } from 'react-toastify'

import type { EditarPropostaFormValues } from '@/components/proposta/EditarPropostaForm/EditarPropostaForm.hook'
import * as fetch from '@/utils/fetch'
import type { AsyncLoadOptions, Translate } from '@/utils/types/common'
import type {
  Cliente,
  Convite,
  Distribuidor,
  Endereco,
  Integrador,
  Perfil,
  User,
  Vendedor,
} from '@/utils/types/structs/auth'
import type { Funding } from '@/utils/types/structs/funding'

import { BAD_LUCKY_MESSAGE } from './constants'
import { dedupTailwind, numonly } from './core/etc'
import { env } from './envs'
import type { states } from './types'
import { statesOptions } from './types'
import type { EscolherPerfilResponseDto } from './types/api/portal/dto'
import type { ListDistribuidorResponseDto } from './types/api/portal/dtos/distribuidor'
import type { ListIntegradorResponseDto } from './types/api/portal/dtos/integrador'
import type {
  ListVendedoresConvidadosResponseDto,
  ListVendedoresResponseDto,
} from './types/api/portal/dtos/vendedores'
import type { TipoDocumentoFormulario } from './types/structs/formulario'
import type { Proposta } from './types/structs/proposta'

/**
 * This function concatenates classnames
 */
export const c = (...arr: (string | undefined | null | false)[]) => {
  const classes = dedupTailwind(
    arr
      // eslint-disable-next-line no-extra-boolean-cast
      .flatMap((s) => (!!s ? s.split(/\s+/) : []))
      .filter((s) => !!s && s !== 'undefined')
      .join(' ')
  )

  return classes.length < 1 ? undefined : classes
}

export const shortenName = (fullName?: string | null) => {
  if (!fullName) return undefined

  const [first = '', second = '', third = ''] = fullName.split(' ')

  if (second.replace(/s$/, '').length < 3) {
    return `${first} ${second} ${third}`.trim()
  } else {
    return `${first} ${second}`.trim()
  }
}

export const firstLetters = (str?: string) => {
  if (!str) return ''
  const tokens = str.split(' ')

  if (tokens.length > 1) {
    return tokens
      .map((s) => s.slice(0, 1))
      .join('')
      .toUpperCase()
  } else {
    return tokens[0].slice(0, 2).toUpperCase()
  }
}

export const array = <T>(v: T | T[]) => {
  return Array.isArray(v) ? v : [v]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateFormValues = (schema: any) => async (values: any) => {
  if (typeof schema === 'function') {
    schema = schema()
  }
  try {
    await schema.validate(values, { abortEarly: false })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errors = err.inner.reduce((formError: any, innerError: any) => {
      return setIn(formError, innerError.path, innerError.message)
    }, {})

    return errors
  }
}

interface TabelaPriceParams {
  nominal?: number
  entrada?: number
  taxa: number
  carencia?: number
  parcelas?: number
}

export const tabelaPrice = ({
  nominal = 0,
  entrada = 0,
  taxa,
  carencia = 0,
  parcelas = 0,
}: TabelaPriceParams) => {
  const vp = (nominal - entrada) * Math.pow(1 + taxa, carencia)
  let pmt = 0

  // https://www.guiadoexcel.com.br/planilha-price-com-carencia-no-excel/
  if (taxa === 0) {
    pmt = vp / parcelas
  } else {
    pmt = vp * (taxa / (1 - Math.pow(1 + taxa, -parcelas)))
  }

  return {
    vp,
    pmt: pmt === Infinity ? 0 : pmt,
  }
}

interface OptionsFetcherOptions {
  filter?: (v: number, i: number, arr: number[]) => boolean
  pluralSufix?: string
  singularSufix?: string
}

export const optionsFetcher = (
  from: number,
  to: number,
  { filter, pluralSufix, singularSufix }: OptionsFetcherOptions
) => {
  return [...new Array(to + 1)]
    .map((_, i) => i)
    .slice(from, to + 1)
    .filter(filter || (() => true))
    .map((v) => ({
      label:
        v > 1 || v === 0
          ? `${v.toString()}${pluralSufix}`
          : `${v.toString()}${singularSufix}`,
      value: v.toString(),
    }))
}
export const comprometimentoRendaMaximoOptions = [
  { label: 'Sem limite', value: null },
  ...optionsFetcher(10, 100, {
    filter: (v) => v % 10 === 0,
    pluralSufix: '%',
    singularSufix: '%',
  }),
]

export const monthsFetcher = (from: number, to: number, t: Translate) =>
  optionsFetcher(from, to, {
    singularSufix: ` ${t('proposal.month')}`,
    pluralSufix: ` ${t('proposal.months')}`,
  })

export const parcelaFetcher = (from: number, to: number, t: Translate) =>
  optionsFetcher(Math.max(from, 1), to, {
    singularSufix: ` ${t('proposal.installment')}`,
    pluralSufix: ` ${t('proposal.installments')}`,
    filter: (v) => v < 7 || v % 12 === 0,
  })

export const strtonum = (s?: string | null, locale = env.LANGUAGE): number => {
  if (!s) return 0
  switch (locale.toLowerCase().replace(/[-_]/g, '')) {
    default:
    case 'br':
    case 'ptbr':
      return +s.replace(/[^\d,-]/g, '').replace(',', '.') || 0

    case 'enus':
    case 'us':
    case 'en':
      return +s.replace(/[^\d.-]/g, '') || 0
  }
}

export const strtonumStrict = (
  s?: string | null,
  def: number | null = 0,
  locale = env.LANGUAGE
) => {
  if (!s) return null
  switch (locale.toLowerCase().replace(/[-_]/g, '')) {
    default:
    case 'br':
    case 'ptbr':
      return +s.replace(/[^\d,-]/g, '').replace(',', '.') || def

    case 'enus':
    case 'us':
    case 'en':
      return +s.replace(/[^\d.-]/g, '') || def
  }
}

export const numtostr = (
  n?: number | null,
  o?: Intl.NumberFormatOptions,
  lang = env.LANGUAGE
): string | undefined => {
  if (n === null || n === undefined) return undefined
  return n?.toLocaleString(lang, {
    maximumFractionDigits: 2,
    ...o,
  })
}

export const money = (s?: string | number | null, locale = env.LANGUAGE) => {
  let num = 0

  if (typeof s === 'number') {
    num = s
  } else {
    num = strtonum(s)
  }

  let value = (num || 0).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })

  if (locale === 'en') {
    value = (num || 0).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
  }
  return value
}

export const getStringFromApiMessage = (
  r: string | Record<string, unknown>
) => {
  let message: string | undefined = undefined

  if (typeof r === 'string') message = r

  if (typeof r === 'object') message = Object.values(r)[0] as string | undefined

  if (typeof r !== 'string' && typeof r?.message === 'string')
    message = r.message

  if (typeof r !== 'string' && typeof r?.message === 'object')
    message = Object.values(r?.message || {})[0] as string | undefined

  return message
}

export const defaultToastError = (r: unknown) => {
  let message = getStringFromApiMessage(r as string | Record<string, unknown>)

  if (!message) message = BAD_LUCKY_MESSAGE

  if (message.includes('this.#prisma') || /tx\..+\..+/.test(message))
    message = BAD_LUCKY_MESSAGE

  // Workaround usado para deduplicar mensagens
  const hash = message

  toast.error(message, {
    toastId: hash,
    updateId: hash,
  })
}

export const fakeDocumentId = (str: string) => {
  return {
    toString() {
      return str
    },
  }
}

export const makePrismaWhere = (
  search: string,
  schema: { OR?: string[]; AND?: string[] }
) => {
  const where = {} as any

  Object.entries(schema).forEach(([operator, keys]) => {
    keys.forEach((key, i) => {
      let transform = search

      if (
        key.toLowerCase().endsWith('cpf') &&
        search.length < 15 &&
        /^[0-9]{0,3}\.?[0-9]{0,3}\.?[0-9]{0,3}-?[0-9]{0,2}$/gm.test(search)
      ) {
        transform = IMask.pipe(search, { mask: '000.000.000-00' })
      }

      if (
        key.toLowerCase().endsWith('cnpj') &&
        search.length < 19 &&
        /^[0-9]{0,2}\.?[0-9]{0,3}\.?[0-9]{0,3}\.?\/?[0-9]{0,4}-?[0-9]{0,2}$/gm.test(
          search
        )
      ) {
        transform = IMask.pipe(search, { mask: '00.000.000/0000-00' })
      }

      if (key.toLowerCase().endsWith('id')) {
        if (isUUID.v4(search)) {
          op.set(where, `${operator}.${i}.${key}`, transform)
        }
      } else {
        op.set(where, `${operator}.${i}.${key}.contains`, transform)
        op.set(where, `${operator}.${i}.${key}.mode`, 'insensitive')
      }
    })
  })

  where.OR = where.OR?.filter(Boolean)
  where.AND = where.AND?.filter(Boolean)

  return where
}

_dayjs.locale(env.LANGUAGE)
_dayjs.extend(timezone)
_dayjs.extend(utc)
_dayjs.extend(customParseFormat)
export const dayjs = _dayjs

export const calculaValorDaTaxa = (
  valor_parcela: number,
  numero_parcelas: number,
  valor_presente: number
) => {
  const valorTotal = valor_parcela * numero_parcelas
  if (valorTotal === valor_presente) {
    return 0
  }
  const variacao = (valorTotal - valor_presente) / valor_presente
  const valorDaTaxa = variacao / numero_parcelas
  return valorDaTaxa
}

export const isIntegradorIncompleto = (i?: Integrador) => {
  if (!i) return false

  if (!i.ConcessionariaAcessos || !i.equipamentos) return true

  if (i.ConcessionariaAcessos.length < 1 || i.equipamentos.length < 1) {
    return true
  }

  return false
}

const urlC = '[a-zA-z\\d-]'
const urlD = [
  'com',
  'com\\.br',
  'br',
  'org',
  'net',
  'app',
  'site',
  'xyz',
  'gov\\.br',
  'dev',
]

// prettier-ignore
export const matchUrl = new RegExp(`^(https?:\\/\\/)?(${urlC}+(\\.${urlC}+)?)+?(${urlC}+\\.(${urlD.join('|')}))\\/?$`, 'm')

const debounced = <T extends (...args: any) => any>(cb: T, millis = 350) => {
  let timeout: NodeJS.Timeout
  return (...args: Parameters<T>) =>
    new Promise<Awaited<ReturnType<T>>>((resolve) => {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(() => {
        resolve(cb(...args))
      }, millis)
    })
}

const _loadIntegradoresOptions: AsyncLoadOptions<
  Integrador,
  { vendedor?: Vendedor }
> = async (search, _, { page, vendedor } = { page: 1 }) => {
  const LIMIT = 10
  const params = new URLSearchParams()

  params.set('page', page.toString())
  params.set('limit', LIMIT.toString())

  params.set(
    'filter',
    JSON.stringify({
      status: { in: ['APROVADO', 'REDLIST'] },
      ...(env.LANGUAGE === 'pt-br' && {
        assinaturas: { some: { assinado: true } },
      }),
      ...(search
        ? makePrismaWhere(search, {
            OR: [
              'identidade.nomeCompleto',
              'empresa.razaoSocial',
              'empresa.nomeFantasia',
            ],
          })
        : {}),
    })
  )

  if (vendedor?.integradorId) {
    return fetch
      .portal<Integrador>(`/integrador/${vendedor.integradorId}`)
      .then(({ data }) => {
        const integradores: Integrador[] = [data]

        return {
          options: integradores.map((u) => ({
            label: u.identidade?.nomeCompleto || u.credencial?.email || '-',
            value: u,
          })),
          additional: { page: page + 1 },
          hasMore: integradores.length === LIMIT,
        }
      })
  }

  return fetch
    .portal<ListIntegradorResponseDto>(`/integrador?${params}`)
    .then(({ data: { integradores } }) => ({
      options: integradores.map((u) => ({
        label:
          u.empresa?.razaoSocial ||
          u.empresa?.nomeFantasia ||
          u.identidade?.nomeCompleto ||
          '-',
        value: u,
      })),
      additional: { page: page + 1 },
      hasMore: integradores.length === LIMIT,
    }))
}

export const loadIntegradoresOptions = debounced(_loadIntegradoresOptions)

const _loadIntegradoresOptions2 = async (
  search: any,
  _: any,
  { page } = { page: 1 }
) => {
  const LIMIT = 10
  const params = new URLSearchParams()

  params.set('page', page.toString())
  params.set('limit', LIMIT.toString())

  params.set(
    'filter',
    JSON.stringify({
      status: { in: ['APROVADO', 'REDLIST'] },
      ...(search
        ? makePrismaWhere(search, {
            OR: [
              'identidade.nomeCompleto',
              'empresa.razaoSocial',
              'empresa.nomeFantasia',
            ],
          })
        : {}),
    })
  )

  return fetch
    .portal<ListIntegradorResponseDto>(`/integrador?${params}`)
    .then(({ data: { integradores } }) => ({
      options: integradores.map((u) => ({
        label: u.empresa?.nomeFantasia || '-',
        value: u.id,
      })),
      additional: { page: page + 1 },
      hasMore: integradores.length === LIMIT,
    }))
}

export const loadIntegradoresOptions2 = debounced(_loadIntegradoresOptions2)

const _loadVendedoresOptions: AsyncLoadOptions<Vendedor | Convite> = async (
  search,
  _,
  { page } = { page: 1 }
) => {
  const LIMIT = 10
  const params = new URLSearchParams()

  params.set('page', page.toString())
  params.set('limit', LIMIT.toString())

  params.set(
    'filter',
    JSON.stringify({
      ...(search
        ? makePrismaWhere(search, {
            OR: ['identidade.nomeCompleto'],
          })
        : {}),
    })
  )

  const listVendedoresConvidados = await fetch
    .portal<ListVendedoresConvidadosResponseDto>(
      `/convite/convidados-perfil/vendedor?${params}`
    )
    .then(({ data: { convidados } }) => ({
      options: convidados.map((u) => ({
        label: u.nomeDestinatario || u.email || '-',
        value: {
          ...u,
          id: u.id,
        },
      })),
      additional: { page: page + 1 },
      hasMore: convidados.length === LIMIT,
    }))

  const listVendedores = await fetch
    .portal<ListVendedoresResponseDto>(`/vendedor?${params}`)
    .then(({ data: { vendedores } }) => ({
      options: vendedores.map((u) => ({
        label: u.identidade?.nomeCompleto || '-',
        value: u,
      })),
      additional: { page: page + 1 },
      hasMore: vendedores.length === LIMIT,
    }))

  return {
    options: listVendedoresConvidados.options.concat(listVendedores.options),
    additional:
      listVendedoresConvidados.additional || listVendedores.additional,
    hasMore: listVendedoresConvidados.hasMore || listVendedores.hasMore,
  }
}

export const loadVendedoresOptions = debounced(_loadVendedoresOptions)

const _loadDistribuidoresAllOptions2: AsyncLoadOptions<string> = async (
  search,
  _,
  { page } = { page: 1 }
) => {
  const params = new URLSearchParams()
  const LIMIT = 10

  params.set('limit', LIMIT.toString())
  params.set('page', page.toString())

  if (search) {
    params.set(
      'filter',

      JSON.stringify({
        status: { not: 'BLOCKLIST' },
        ...makePrismaWhere(search, {
          OR: ['empresa.razaoSocial', 'empresa.cnpj', 'empresa.nomeFantasia'],
        }),
      })
    )
  } else {
    params.set(
      'filter',
      JSON.stringify({
        status: { not: 'BLOCKLIST' },
      })
    )
  }

  const {
    data: { distribuidores },
  } = await fetch.portal<ListDistribuidorResponseDto>(
    `/distribuidor-all?${params}`
  )
  return {
    options: distribuidores.map((d) => ({
      label: `${
        shortenName(d.empresa?.razaoSocial || d.empresa?.nomeFantasia) || ''
      } - ${d.empresa?.cnpj || ''}`,
      value: d.id,
    })),
    additional: { page: page + 1 },
    hasMore: distribuidores.length === LIMIT,
  }
}

export const loadDistribuidoresAllOptions2 = debounced(
  _loadDistribuidoresAllOptions2
)

const _loadDistribuidoresAllOptions: AsyncLoadOptions<Distribuidor> = (
  search,
  _,
  { page } = { page: 1 }
) => {
  const params = new URLSearchParams()
  const LIMIT = 10

  params.set('limit', LIMIT.toString())
  params.set('page', page.toString())

  if (search) {
    params.set(
      'filter',

      JSON.stringify({
        status: { not: 'BLOCKLIST' },
        ...makePrismaWhere(search, {
          OR: ['empresa.razaoSocial', 'empresa.cnpj', 'empresa.nomeFantasia'],
        }),
      })
    )
  } else {
    params.set(
      'filter',
      JSON.stringify({
        status: { not: 'BLOCKLIST' },
      })
    )
  }

  return fetch
    .portal<ListDistribuidorResponseDto>(`/distribuidor-all?${params}`)
    .then(({ data: { distribuidores } }) => {
      return {
        options: distribuidores.map((d) => ({
          label: `${
            shortenName(d.empresa?.razaoSocial || d.empresa?.nomeFantasia) || ''
          } - ${d.empresa?.cnpj || ''}`,
          value: d,
        })),
        additional: { page: page + 1 },
        hasMore: distribuidores.length === LIMIT,
      }
    })
}

export const loadDistribuidoresAllOptions = debounced(
  _loadDistribuidoresAllOptions
)

const _loadDistribuidoresOptions: AsyncLoadOptions<Distribuidor> = (
  search,
  _,
  { page, integradorId }: { page: number; integradorId?: string } = { page: 1 }
) => {
  const params = new URLSearchParams()
  const LIMIT = 10

  params.set('limit', LIMIT.toString())
  params.set('page', page.toString())

  const searchString = JSON.stringify({
    ...(integradorId ? { integradores: { some: { id: integradorId } } } : {}),

    ...(search
      ? makePrismaWhere(search, {
          OR: ['empresa.razaoSocial', 'empresa.cnpj', 'empresa.nomeFantasia'],
        })
      : {}),
  })

  params.set('filter', searchString)

  return fetch
    .portal<ListDistribuidorResponseDto>(`/distribuidor?${params}`)
    .then(({ data: { distribuidores } }) => {
      return {
        options: distribuidores.map((d) => ({
          label: `${
            shortenName(d.empresa?.razaoSocial || d.empresa?.nomeFantasia) || ''
          } - ${d.empresa?.cnpj || ''}`,
          value: d,
        })),
        additional: { page: page + 1 },
        hasMore: distribuidores.length === LIMIT,
      }
    })
}

export const loadDistribuidoresOptions = debounced(_loadDistribuidoresOptions)

export const extractJWTPayload = <T>(jwt?: string | null): T | null => {
  if (!jwt) return null

  const split = jwt.split('.')

  if (split.length !== 3) return null

  try {
    const debased = Buffer.from(split[1], 'base64').toString()
    const json = JSON.parse(debased)
    return json as T
  } catch {
    return null
  }
}

export const isVendedorDoDistribuidor = (u?: Perfil | Vendedor) => {
  if (!u) return false
  const profile = 'perfil' in u ? (u.perfil as Vendedor) : (u as Vendedor)
  return Boolean(profile.distribuidorId)
}

export const isVendedorDoIntegrador = (u?: Perfil | Vendedor) => {
  if (!u) return false
  const profile = 'perfil' in u ? (u.perfil as Vendedor) : (u as Vendedor)
  return Boolean(profile.integradorId)
}

export const isUserBlockedToCreateProposals = (
  user?: Perfil,
  assinatura?: boolean | null
) => {
  if (!user) return true
  const { perfil } = user

  const integrador = user.credencial?.roles.filter(
    (item: string) => item === 'INTEGRADOR'
  )
  const distribuidor = user.credencial?.roles.filter(
    (item: string) => item === 'DISTRIBUIDOR'
  )
  const vendedor = user.credencial?.roles.filter(
    (item: string) => item === 'VENDEDOR'
  )
  const backoffice = user.credencial?.roles.filter(
    (item: string) => item === 'BACKOFFICE'
  )

  if (
    integrador?.length &&
    ['APROVADO', 'BLOCKLIST', 'REDLIST'].includes(perfil?.status) &&
    (assinatura || env.LANGUAGE === 'en')
  ) {
    return false
  }

  if (
    distribuidor?.length &&
    ['APROVADO', 'BLOCKLIST', 'REDLIST'].includes(perfil.status)
  ) {
    return false
  }

  if (backoffice?.length) {
    return false
  }
  if (vendedor?.length) {
    if (perfil.integrador) {
      if (
        !assinatura ||
        (assinatura && perfil.integrador.status !== 'REPROVADO')
      ) {
        return false
      } else {
        return true
      }
    } else if (
      perfil.distribuidor &&
      perfil.distribuidor.status !== 'REPROVADO'
    ) {
      return false
    }
    return true
  }

  return true
}

export const toVendedor = (u: Perfil | User) => {
  return 'perfil' in u
    ? (u.perfil as Vendedor | undefined)
    : (u as Vendedor | undefined)
}

export const toIntegrador = (u: Perfil | User) => {
  return 'perfil' in u
    ? (u.perfil as Integrador | undefined)
    : (u as Integrador | undefined)
}

export const toCliente = (u: Perfil | User) => {
  return 'perfil' in u
    ? (u.perfil as Cliente | undefined)
    : (u as Cliente | undefined)
}

export const toDistribuidor = (u: Perfil | User) => {
  return 'perfil' in u
    ? (u.perfil as Distribuidor | undefined)
    : (u as Distribuidor | undefined)
}

export const toFinanciador = (u: Perfil | User) => {
  return 'perfil' in u
    ? (u.perfil as Funding | undefined)
    : (u as Funding | undefined)
}

export const toStateOption = (state?: (typeof states)[number]) => {
  if (!state) return undefined
  return statesOptions.find(({ value }) => value === state)
}

export const capitalize = (str?: string | null) => {
  if (!str) return str || undefined
  return str
    .toLowerCase()
    .split(' ')
    .map((s) => `${s.charAt(0).toUpperCase()}${s.slice(1)}`)
    .join(' ')
}

export const datestr = (v?: string | Date | number | null, hour?: boolean) => {
  if (!v) return null

  return hour
    ? dayjs(v).format('DD/MM/YYYY HH:MM')
    : dayjs(v).format('DD/MM/YYYY')
}

export const today = new Date()
export const sevenDaysAgo: Date = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
export const thirtyDaysAgo = new Date(new Date().setDate(today.getDate() - 30))
export function formatDate(date: Date) {
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear()

  return `${month}/${day}/${year}`
}

export const mesOuMeses = (v: number, t: any) =>
  v === 1 ? ' ' + t('financiers.month') : ' ' + t('financiers.months')

export const calcularIdade = (d1?: string | null, d2?: string | null) => {
  if (!d1) return
  const _date1 = new Date(d1)
  const _date2 = d2 ? new Date(d2) : new Date()

  // https://stackoverflow.com/questions/4076321/javascript-age-calculation
  let years = _date2.getFullYear() - _date1.getFullYear()

  if (
    _date2.getMonth() < _date1.getMonth() ||
    (_date2.getMonth() == _date1.getMonth() &&
      _date2.getDate() < _date1.getDate())
  ) {
    years--
  }

  return years
}

export const isISODate = (v?: string | null) => {
  if (!v) return false
  const exp =
    /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/
  return exp.test(v)
}

export const percentage = (n?: number | null) =>
  numtostr((n || 0) * 100, {
    maximumFractionDigits: 2,
  }) + '%'

export const arrayIntersect = <T>(arr1: T[], arr2: T[]) => {
  const result = [] as T[]
  const a1bigger = arr1.length > arr2.length

  ;(a1bigger ? arr1 : arr2).forEach((v) => {
    if ((a1bigger ? arr2 : arr1).includes(v)) result.push(v)
  })

  return result
}

export const propostaDocsByTipoFixo = (
  tipoFixo:
    | TipoDocumentoFormulario['tipoFixo']
    | TipoDocumentoFormulario['tipoFixo'][],
  proposta: Proposta
) => {
  const values = Array.isArray(tipoFixo) ? tipoFixo : [tipoFixo]
  return (
    proposta.documentos?.filter(
      ({ slot }) =>
        slot?.tipoDocumento?.tipoFixo &&
        values.includes(slot.tipoDocumento.tipoFixo)
    ) || []
  )
}

export const isNullorUndef = <T>(
  v: T | undefined | null
): v is null | undefined => v === null || v === undefined

export const menuItensHeader = [
  {
    label: 'menu-item-partnes',
    link: '/parceiros',
    disabled: false,
  },
  {
    label: 'menu-item-financiers',
    link: '/financiadores',
    disabled: false,
  },
  {
    label: 'menu-item-customers',
    link: '/clientes',
    disabled: false,
  },
  {
    label: 'menu-item-about-us',
    link: '/sobre-nos',
    disabled: false,
  },
  {
    label: 'menu-item-esg',
    link: '/esg',
    disabled: false,
  },
  {
    label: 'menu-item-blog',
    link: 'https://academy.eosfin.com.br/',
    disabled: false,
  },
]
/**
 * Passando uma proposta para a função, ela identifica se o originador da proposta é um integrador ou distribuidor
 * @param proposta
 * @type Proposta
 * @return string - integrador | distribuidor
 */
export const getOriginadorProposta = (proposta: Proposta) => {
  const criadoPorId = proposta.criadoPorId
  const integradorId = proposta.integradorId

  if (proposta.criadoPorPerfil === 'VENDEDOR') {
    return proposta.integrador ? 'integrador' : 'distribuidor'
  }
  if (criadoPorId === integradorId) {
    return 'integrador'
  } else {
    return 'distribuidor'
  }
}

export const returnBodyEdicaoPropostaUs = (
  params: EditarPropostaFormValues
) => {
  const { noCredit } = params
  const body = {
    ...(params.tipoServico
      ? {
          servicos: Object.entries(params.tipoServico)
            .filter(([_, value]) => value === true)
            .map(([key]) => key),
        }
      : {}),
    ...(params.projectTypes
      ? {
          projectTypes: Object.entries(params.projectTypes)
            .filter(([_, value]) => value === true)
            .map(([key]) => key),
        }
      : {}),
    ...(params.projeto.tipoProjeto && {
      tipoProjeto: params.projeto.tipoProjeto,
    }),
    ...(strtonum(params.kwhUltimoAno, 'en') && {
      kwhUltimoAno: strtonum(params.kwhUltimoAno, 'en'),
    }),
    ...(strtonum(params.kwhProjetado, 'en') && {
      kwhProjetado: strtonum(params.kwhProjetado, 'en'),
    }),
    ...(strtonum(params.mediaContaEnergia, 'en') && {
      mediaContaEnergia: strtonum(params.mediaContaEnergia, 'en'),
    }),
    ...(strtonum(params.valorProjeto) && {
      valorProjeto: strtonum(params.valorProjeto, 'en'),
    }),
    cliente: {
      ...(params.propostaCliente?.nome && {
        nome: params.propostaCliente?.nome,
      }),
      ...(params.propostaCliente?.sobrenome && {
        sobrenome: params.propostaCliente?.sobrenome,
      }),
      ...(params.propostaCliente?.dataNascimento && {
        dataNascimento: params.propostaCliente?.dataNascimento,
      }),
      ...(params.propostaCliente?.email && {
        email: params.propostaCliente?.email,
      }),
      ...(params.propostaCliente?.ssn && {
        ssn: params.propostaCliente?.ssn.replaceAll('-', ''),
      }),
      ...(params.propostaCliente?.telefone && {
        telefone: params.propostaCliente?.telefone
          .replaceAll('(', '')
          .replaceAll(')', '')
          .replaceAll('-', '')
          .replaceAll(' ', ''),
      }),
      ...(params.propostaCliente?.tipoTelefone && {
        tipoTelefone: params.propostaCliente?.tipoTelefone.value,
      }),
      ...(env.LANGUAGE === 'en'
        ? {
            ...(params.propostaCliente?.addressLine1
              ? { addressLine1: params.propostaCliente?.addressLine1 }
              : {}),
            ...(params.propostaCliente?.addressLine2
              ? { addressLine2: params.propostaCliente?.addressLine2 }
              : {}),
          }
        : {
            ...(params.propostaCliente?.logradouro && {
              logradouro: params.propostaCliente?.logradouro,
            }),
            ...(params.propostaCliente?.cidade && {
              cidade: params.propostaCliente?.cidade,
            }),
            ...(params.propostaCliente?.estado && {
              estado: params.propostaCliente?.estado,
            }),
            ...(params.propostaCliente?.cep && {
              cep: params.propostaCliente?.cep,
            }),
            ...(strtonum(params.propostaCliente?.rendaAnual, 'en') && {
              rendaAnual: strtonum(params.propostaCliente?.rendaAnual, 'en'),
            }),
          }),
      ...(params.propostaCliente?.situacaoResidencial && {
        situacaoResidencial: params.propostaCliente?.situacaoResidencial.value,
      }),
      ...(params.propostaCliente?.situacaoProfissional && {
        situacaoProfissional:
          params.propostaCliente?.situacaoProfissional.value,
      }),
      ...(params.propostaCliente?.ocupacao && {
        ocupacao: params.propostaCliente?.ocupacao,
      }),
      ...(params.propostaCliente?.nomeEmpresa && {
        nomeEmpresa: params.propostaCliente?.nomeEmpresa,
      }),
      ...(params.propostaCliente?.tempoEmpresaMeses && {
        tempoEmpresa: params.propostaCliente?.tempoEmpresaMeses,
      }),
    },
    ...(params.loanType && {
      loanType: params.loanType.name,
    }),
    ...((params.loanType?.name === 'HOMESHARE' ||
      params.loanType?.name === 'NO_CREDIT') && {
      remainingMortgage: strtonum(params.remainingMortgage),
    }),

    ...(params.primaryIncomeSource && {
      primaryIncomeSource: params.primaryIncomeSource.value,
      ...(['FULLTIME', 'PARTTIME', 'SELFEMPLOYED'].includes(
        params.primaryIncomeSource.value
      ) && {
        yearsWorking: strtonum(params.yearsWorking),
        ...(params.primaryIncomeSource.value !== 'SELFEMPLOYED' && {
          employerName: params.employerName,
        }),
      }),
    }),

    primaryActivity: params.primaryActivity,
    monthlyIncome: strtonum(params.monthlyIncome),
    otherMonthlyIncome: strtonum(params.otherMonthlyIncome),
    otherMonthlyIncomeDescription: params.otherMonthlyIncomeDescription,

    noCredit:
      params.loanType?.name === 'NO_CREDIT' && noCredit
        ? {
            peopleCount: +noCredit.peopleCount,
            liveIn: noCredit.liveIn,
            isRental: noCredit.isRental,
            armedForcesStatus: noCredit.armedForcesStatus.value,
            emergencyContactName1: noCredit.emergencyContactName1,
            emergencyContactPhone1: numonly(noCredit.emergencyContactPhone1),
            emergencyContactName2: noCredit.emergencyContactName2,
            emergencyContactPhone2: numonly(noCredit.emergencyContactPhone2),
          }
        : undefined,

    ...(params.thirdPartyOwner && {
      thirdPartyOwner: params.thirdPartyOwner.value,
    }),
    ...(params.needsHomeownerInsurance && {
      needsHomeownerInsurance: params.needsHomeownerInsurance.value,
    }),
  }

  return body
}

interface IGeraResultadoFinalAprovacao {
  resultadoP1Analise: null | 'APROVADO' | 'REPROVADO' | 'PENDENTE' | undefined
  finalResultAnaliseProposta: boolean | undefined
}

export const geraResultadoFinalAprovacao = ({
  resultadoP1Analise,
  finalResultAnaliseProposta,
}: IGeraResultadoFinalAprovacao) => {
  if (resultadoP1Analise === 'REPROVADO') {
    return 'REPROVADO'
  }
  if (
    finalResultAnaliseProposta === true &&
    resultadoP1Analise === 'APROVADO'
  ) {
    return 'APROVADO'
  } else {
    return 'PENDENTE'
  }
}

export const EnvioAutomaticoDeFicha = async (propostaId: string) => {
  return fetch.portal<EscolherPerfilResponseDto>(`/ficha/criacao-automatica`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ propostaId }),
  })
}

export function parseAddressToString(address: Endereco) {
  return [
    [address.logradouro, address.numero].filter(Boolean).join(', '),
    [address.complemento, address.bairro].filter(Boolean).join(', '),
    [address.cidade, address.estado].filter(Boolean).join(', '),
    address.cep,
  ]
    .filter(Boolean)
    .join(' - ')
}

export function shiftOdds<T extends any[]>(arr: T) {
  const mid = arr.length % 2 === 0 ? arr.length / 2 : arr.length / 2 + 1
  const firstHalf = arr.slice(0, mid)
  const secondHalf = arr.slice(mid)

  const newarr = [] as unknown as T

  let firstIdx = 0
  let secondIdx = 0

  arr.forEach((_, i) => {
    if (i % 2 === 0) {
      newarr.push(firstHalf[firstIdx++])
    } else {
      newarr.push(secondHalf[secondIdx++])
    }
  })

  return newarr
}

export function cookies() {
  return Object.fromEntries(
    document.cookie.split('; ').map((s) => s.split('='))
  )
}
