import type { Socket } from 'socket.io-client'

import type { Geolocation } from '@/components/core/OzStreetView/OzStreetView'
import { fetchProjectTypes } from '@/hooks/core/useProjectTypes'
import { fetchFinanciers } from '@/hooks/funding/use-financiadores'
import { fetchLoanTypes } from '@/hooks/proposta/use-loan-types'
import {
  SCHEMA_PARCELAS_COM_SEGURO,
  SCHEMA_PARCELAS_SEM_GARANTIA,
} from '@/utils/constants'
import {
  capitalize,
  dayjs,
  defaultToastError,
  makePrismaWhere,
  tabelaPrice,
  toCliente,
  toDistribuidor,
  toIntegrador,
  toVendedor,
} from '@/utils/etc'
import * as fetch from '@/utils/fetch'
import { so_irr, travishorn_irr } from '@/utils/math'
import type { TableExportMode, TableExportType } from '@/utils/types'
import { statesOptions } from '@/utils/types'
import type { AsyncLoadOptions, Translate } from '@/utils/types/common'
import {
  type Endereco,
  type Perfil,
  type TipoCredencial,
  type User,
} from '@/utils/types/structs/auth'
import type { Proposta, StatusProposta } from '@/utils/types/structs/proposta'

import type { Region } from '../types/etc'

interface fields {
  key: string
  fields: string[]
}

type Field = (string | fields)[]

export interface ExportarOptions {
  type: TableExportType
  mode: TableExportMode
  ids?: string[]
  fields?: Field
  filters?: Record<string, unknown>
  sellers?: boolean
}

export const plural = (str: string) => {
  if (str.toLowerCase().endsWith('or')) return `${str}es`
  if (str.toLowerCase().endsWith('ão')) return `${str.slice(0, -2)}ões`
  return `${str}s`
}

export const downloadCreditAnalysis = async (
  projectId: string,
  protocol: string
) => {
  const date = dayjs(Date.now()).format('DDMMYYYY')
  const filename = `analise-credito-${protocol}-${date}.pdf`
  return await fetch
    .portal(`/proposta/${projectId}/download-credit-analysis-pdf`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      noparse: true,
    })
    .then(async (response: unknown) => {
      const r = response as Response
      const blob = await r.blob()
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = filename
      document.body.appendChild(a)
      a.click()
      a.remove()
    })
    .catch(async (err) => {
      throw await err.json()
    })
    .catch(defaultToastError)
}

export const exportar = ({
  type,
  mode,
  ids,
  filters,
  fields,
  sellers,
}: ExportarOptions) => {
  const params = new URLSearchParams()
  const data = {
    ids: ids ? ids : [],
    fields: fields ? fields : [],
    sellers: sellers,
  }

  if (filters) {
    params.set('filter', JSON.stringify(filters))
  }

  const ext: Record<TableExportType, string> = {
    excel: 'xlsx',
    pdf: 'pdf',
  }

  const url =
    {
      proposta: `/exportar/propostas/${type}?${params}`,
      instalação: `/exportar/instalacoes/${type}?${params}`,
      financeira: `/exportar/fundings/${type}?${params}`,
    }[mode as string] || `/exportar/usuarios/${mode}/${type}?${params}`

  const date = dayjs(Date.now()).format('DD-MM-YYYY')
  const filename = `${plural(mode)}-${date}.${ext[type]}`

  return fetch
    .portal(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
      noparse: true,
    })
    .then(async (response: unknown) => {
      const r = response as Response
      const blob = await r.blob()
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = filename
      document.body.appendChild(a)
      a.click()
      a.remove()
    })
    .catch(async (err) => {
      throw await err.json()
    })
    .catch(defaultToastError)
}

export const numonly = (s?: string | null) => {
  if (!s) return null
  // used to keep signed numbers signed
  const sign = s.match(/^([+-])/)?.[1] || ''
  const replaced = s.replace(/[^\d]+/g, '')
  return `${sign}${replaced}`
}

export const dedupTailwind = (str: string) => {
  const nonCollidable = ['border-', 'rounded-', 'text-', 'snap-']
  const speciallyCollidable: Record<string, string[]> = {
    'px-': ['p-'],
    'py-': ['p-'],
    'p-': ['py-', 'px-'],
    flex: ['block'],
    block: ['flex'],
  }

  return str
    .split(/\s+/)
    .filter((v, i, arr) => {
      const makePrefix = (str: string) => {
        const split = str.split('-')
        return split.length > 1 ? `${split.slice(0, -1).join('-')}-` : split[0]
      }
      const prefix = makePrefix(v)
      return (
        nonCollidable.includes(prefix) ||
        !arr
          .slice(i)
          .find(
            (vv) =>
              [prefix, ...(speciallyCollidable[prefix] || [])].includes(
                makePrefix(vv)
              ) && vv !== v
          )
      )
    })
    .join(' ')
}

export const contratoPendente = (user?: Perfil) => {
  if (!user) return user
  const integrador = toIntegrador(user)
  return integrador?.assinaturas?.find(
    ({ assinado, contrato }) => !assinado && !contrato?.inativo
  )?.assinaturaId
}
export const contratoPendenteAssinado = (user?: Perfil) => {
  if (!user) return user
  // const integrador = toIntegrador(user)
  // const vendedor = toVendedor(user)

  const integrador = user.credencial?.roles.filter(
    (item: string) => item === 'INTEGRADOR'
  )
  const vendedor = user.credencial?.roles.filter(
    (item: string) => item === 'VENDEDOR'
  )

  if (integrador?.length) {
    return user?.perfil?.assinaturas?.find(({ assinado }: any) => assinado)
      ?.assinado
  }
  if (vendedor?.length) {
    return user?.perfil?.assinaturas?.find(({ assinado }: any) => assinado)
      ?.assinado
  }
}

export const ext = (str: string) => {
  return `.${str.split('.').slice(-1)[0]}`
}

export const cpfUsuario = (user: Perfil | User) => {
  return (
    toIntegrador(user)?.identidade?.cpf ||
    toVendedor(user)?.identidade?.cpf ||
    toCliente(user)?.identidade?.cpf
  )
}

export const cnpjUsuario = (user: Perfil | User) => {
  return (
    toIntegrador(user)?.empresa?.cnpj ||
    toDistribuidor(user)?.empresa?.cnpj ||
    toCliente(user)?.empresa?.cnpj
  )
}

export const podeComunicarProposta = ({
  context,
  proposta,
  user,
}: {
  context?: TipoCredencial
  proposta: Proposta
  user: Perfil
}) => {
  switch (context) {
    case 'DISTRIBUIDOR':
    case 'INTEGRADOR': {
      const key = context === 'INTEGRADOR' ? 'integradorId' : 'distribuidorId'
      const isOwner =
        [proposta.vendedor?.[key], proposta.criadoPorId].includes(
          user.perfil?.id
        ) ||
        (Boolean(proposta.vendedorConvidado) &&
          proposta.vendedorConvidado?.tipoRemetente === context)

      return isOwner
    }

    case 'VENDEDOR':
      return proposta.criadoPorId === user.perfil?.id

    case 'BACKOFFICE':
    case 'CLIENTE':
      return true

    default:
      return false
  }
}

export const podeEditarProposta = ({
  context,
  proposta,
  user,
}: {
  context?: TipoCredencial
  proposta: Proposta
  user: Perfil
}) => {
  switch (context) {
    case 'DISTRIBUIDOR':
    case 'INTEGRADOR': {
      const key = context === 'INTEGRADOR' ? 'integradorId' : 'distribuidorId'
      const isOwner =
        [proposta.vendedor?.[key], proposta.criadoPorId].includes(
          user.perfil?.id
        ) ||
        (Boolean(proposta.vendedorConvidado) &&
          proposta.vendedorConvidado?.tipoRemetente === context)

      return isOwner && proposta.status === 'SIMULACAO'
    }

    case 'VENDEDOR':
      return (
        proposta.vendedor?.id === user.perfil?.id &&
        proposta.status === 'SIMULACAO'
      )

    case 'BACKOFFICE':
      return !['PAGO', 'CANCELADA'].includes(proposta.status)

    case 'CLIENTE':
      return proposta.status === 'SIMULACAO'

    default:
      return (
        user.perfil?.id === proposta.criadoPorId &&
        proposta.status === 'SIMULACAO'
      )
  }
}

export const defPlural = (
  n: number,
  singularSufix: string,
  pluralSufix: string
) => {
  return `${n}${n > 1 || n < 1 ? ` ${pluralSufix}` : ` ${singularSufix}`}`
}

export const valorParcelaProposta = (proposta: Proposta, taxa: number) => {
  return tabelaPrice({
    taxa: proposta.taxa || taxa,
    carencia: proposta.carencia,
    entrada: proposta.entrada || 0,
    nominal: (proposta.kitFotovoltaico || 0) + (proposta.maoObra || 0),
    parcelas: proposta.tempoFinanciamento,
  }).pmt
}

export const valorProjetoProposta = (proposta: Proposta) => {
  return (proposta.kitFotovoltaico || 0) + (proposta.maoObra || 0)
}

export const nomeUsuario = (perfil?: any) => {
  const text =
    perfil?.nomeFantasia ||
    perfil?.empresa?.nomeFantasia ||
    perfil?.razaoSocial ||
    perfil?.empresa?.razaoSocial ||
    perfil?.identidade?.nomeCompleto ||
    perfil?.nomeCompleto ||
    '-'

  return capitalize(text.toLowerCase())
}

export const nomeClienteProposta = ({
  clienteNome,
  clienteNomeFantasia,
  cliente,
  instalacao,
  propostaCliente,
}: Proposta) => {
  const companyName =
    propostaCliente?.nomeFantasia ||
    clienteNomeFantasia ||
    cliente?.empresa?.nomeFantasia

  const personName =
    propostaCliente?.nomeCompleto ||
    clienteNome ||
    cliente?.identidade?.nomeCompleto

  return companyName || personName || instalacao?.clienteNome
}

export const documentoClienteProposta = ({
  clienteCpf,
  clienteCnpj,
  cliente,
  propostaCliente,
}: Proposta) => {
  const companyDoc =
    propostaCliente?.cnpj || clienteCnpj || cliente?.empresa?.cnpj
  const personDoc =
    propostaCliente?.cpf || clienteCpf || cliente?.identidade?.cpf
  return companyDoc || personDoc
}

export const tipoDocumentoCliente = (
  t?: any,
  p?: Proposta
): 'CPF' | 'CNPJ' | 'Documento' => {
  if (!p) return 'Documento'
  const doc = documentoClienteProposta(p)
  return doc
    ? doc.length > 14
      ? t('common.form_ein')
      : t('common.form-document1')
    : t('proposal.tab_customer_document')
}

export const diffId = <F extends { id?: string }, T extends { id?: string }>(
  from: F[],
  to: T[]
) => {
  const added = to.filter((to) => !from.find((from) => from.id === to.id))
  const removed = from.filter((from) => !to.find((to) => to.id === from.id))
  const updated = to.filter((to) => from.find((from) => from.id === to.id))
  return [added, updated, removed]
}

export const mesSufixo = (t: Translate, n?: number | null) =>
  defPlural(n || 0, t('financiers.month'), t('financiers.months'))

export const fichaSugerida = ({ fichas }: Proposta) => {
  // Ficha aprovada com maior retorno para Eos
  const relevante = (fichas || [])
    .filter(
      ({ status }) => status.startsWith('APROVADA') || status === 'PENDENTE'
    )
    .sort((a) => (a.status.startsWith('APROVADA') ? -1 : 1))
    .map((f) => ({
      ...f,
      // Esse score indica qual ficha é mais rentável
      score:
        Number(f.valorRetorno) + Number(f.valorPlus) + Number(f.valorSpread),
    }))
    .sort((a, b) => {
      if (a.escolhidaCliente) return -1
      if (b.escolhidaCliente) return 1
      if (a.status === b.status) {
        return a.score > b.score ? -1 : 1
      } else {
        return a.status.startsWith('APROVADA') ? -1 : 1
      }
    })[0]

  return relevante || fichas?.slice(-1)?.[0]
}

export const ensureDate = (v?: string | number | null) => {
  try {
    if (!v) return
    const date = new Date(v)
    if (isNaN(+date)) return
    return date
  } catch {
    // ignored
  }
}

interface TirParams {
  nominal: number
  carencia: number
  parcelas: number
  parcela: number
}

export const tir = ({ nominal, carencia, parcelas, parcela }: TirParams) => {
  const doTravishorn = () => {
    return (
      travishorn_irr([
        -nominal,
        ...Array.from({ length: parcelas + carencia }).map((_, i) =>
          i < carencia ? 0 : parcela
        ),
      ]) * 100
    )
  }

  const doStackoverflow = () => {
    return so_irr([
      -nominal,
      ...Array.from({ length: parcelas + carencia }).map((_, i) =>
        i < carencia ? 0 : parcela
      ),
    ])
  }

  for (const doCalc of [doTravishorn, doStackoverflow]) {
    try {
      const n = doCalc()
      if (typeof n === 'number' && !isNaN(n)) return n
    } catch {
      // ignore
    }
  }

  throw new Error('Tentado todos métodos e falhado mizeravelmente')
}
const isObj = (v: unknown): v is Record<string, unknown> => {
  return typeof v === 'object' && v !== null && !Array.isArray(v)
}

export const diffObj = <T extends object>(from: T, to: T) => {
  const result = {} as T

  Object.keys(to).forEach((_k) => {
    const k = _k as unknown as keyof T

    if (isObj(to[k]) && isObj(from[k])) {
      const r = diffObj(from[k] as never, to[k] as never)
      if (r) result[k] = r
    } else {
      if (from[k] === to[k]) return
      if (JSON.stringify(from[k]) === JSON.stringify(to[k])) return
      result[k] = to[k]
    }
  })

  return Object.keys(result).length ? result : undefined
}

export const normalizeBirthdate = (str?: string | null) => {
  return str && dayjs(str.split('T')[0]).toISOString()
}

export const cleanEmptyObj = <T extends object>(obj: T) => {
  const result = {} as T

  Object.keys(obj).forEach((_k) => {
    const k = _k as unknown as keyof T
    const v = obj[k]

    if (isObj(v)) {
      const rr = cleanEmptyObj(v)
      if (Object.keys(rr).length) result[k] = rr
    } else {
      result[k] = obj[k]
    }
  })

  return result
}

export const parseString = (str: string, matchers: RegExp[]) => {
  const matches = matchers
    .map((m) => (m.test(str) ? str.match(m) : false))
    .filter(Boolean) as RegExpMatchArray[]

  return matches.map((m) => {
    const strs = [...new Set([...m.values()].slice(1))]
    const tokenize = strs.reduce((a, b, i) => ({ ...a, [b]: i }), {}) as Record<
      string,
      number
    >

    return {
      template: [...m.values()]
        .slice(1)
        .reduce((a, b) => a.replace(b, `{${tokenize[b]}}`), str),
      values: strs,
    }
  })
}

export const nullObj = <T extends Record<string, unknown>>(
  obj: T
): Record<string, any> => {
  return Object.entries(obj).reduce(
    (a, [key, value]) => ({
      ...a,
      [key]: isObj(value) ? nullObj(value) : null,
    }),
    {}
  )
}

export const sugerirParcelaProposta = (() => {
  if (!(global as any).PARCELA_SUGERIDA_CACHE) {
    ;(global as any).PARCELA_SUGERIDA_CACHE = {}
  }

  const cache: Record<
    string,
    { valorParcela: number; quantidadeParcela: number }
  > = (global as any).PARCELA_SUGERIDA_CACHE

  return ({
    comSeguro,
    valorContaMedia,
    valorEquipamentos,
    valorMaoObra,
    valorEntrada,
    taxa,
  }: {
    comSeguro: boolean
    valorContaMedia: number
    valorEquipamentos: number
    valorMaoObra: number
    valorEntrada: number
    taxa: number
  }) => {
    const id = JSON.stringify({
      comSeguro,
      valorContaMedia,
      valorEquipamentos,
      valorMaoObra,
      valorEntrada,
      taxa,
    })

    if (cache[id]) return cache[id]

    const valorCorte = valorContaMedia - Math.max(50, valorContaMedia * 0.05)
    const schemaParcelas = comSeguro
      ? SCHEMA_PARCELAS_COM_SEGURO
      : SCHEMA_PARCELAS_SEM_GARANTIA

    let valorParcela = 0
    let quantidadeParcela = 0
    let i = 0

    const getPmt = (parcelas: number) =>
      tabelaPrice({
        nominal: valorEquipamentos + valorMaoObra,
        carencia: 3,
        entrada: valorEntrada,
        parcelas,
        taxa,
      }).pmt

    do {
      if (i >= schemaParcelas.length) {
        break
      }

      quantidadeParcela = schemaParcelas[i]
      valorParcela = getPmt(quantidadeParcela)

      i++
    } while (valorParcela > valorCorte)

    cache[id] = { valorParcela, quantidadeParcela }

    return { valorParcela, quantidadeParcela }
  }
})()

export const foiSelecionadoParcelaSugerida = ({
  valorParcela,
  quantidadeParcela,
  valoresCalculo,
}: {
  valorParcela: number
  quantidadeParcela: number
  valoresCalculo: {
    comSeguro: boolean
    valorContaMedia: number
    valorEquipamentos: number
    valorMaoObra: number
    valorEntrada: number
    taxa: number
  }
}) => {
  const result = sugerirParcelaProposta(valoresCalculo)
  return (
    result.valorParcela === valorParcela &&
    result.quantidadeParcela === quantidadeParcela
  )
}

export const descontoGarantia = (g?: boolean | null | 'sim' | 'nao') =>
  g === 'sim' || g === true ? 1 : 1

export const geolocDeEndereco = (e: Endereco): Geolocation => {
  return {
    cep: e.cep,
    rua: e.logradouro,
    bairro: e.bairro,
    cidade: e.cidade,
    estado: statesOptions.find(({ value }) => value === e.estado)?.value,
    numero: e.numero,
  }
}

/**
 * @param {Array<string>}   keyStorage    Chaves dos items que deseja remover do storage.
 * 
 * Description: A função recebe um array de strings, cada string se refere a uma chave no storage, onde a função percorre o array e remove as chaves desejadas
 
 */
export const CleanStorageByKey = (keyStorage: string[]) => {
  keyStorage.map((key) => {
    window.localStorage.removeItem(key)
  })
}

export const isStatusHabilitadoFormalizacao = (status: StatusProposta) => {
  return ['RECEBIDA', 'FORMALIZACAO'].includes(status)
}

export const socketManager = (socket: Socket) => {
  type Listener = (...args: any[]) => void
  let listenersMap: Record<string, Listener[]> = {}
  let anyListenerMap: Listener[] = []

  return {
    on: (ev: string, cb: Listener) => {
      listenersMap[ev] = (listenersMap[ev] || []).concat(cb)
      socket.on(ev, cb)

      if (ev === 'connect' && socket.connected) {
        cb()
      }
    },
    onAny: (cb: Listener) => {
      anyListenerMap.push(cb)
      socket.onAny(cb)
    },
    emit: socket.emit.bind(socket),
    teardown: () => {
      anyListenerMap.forEach((listener) => {
        socket.offAny(listener)
      })

      Object.entries(listenersMap).forEach(([ev, listeners]) => {
        listeners.forEach((listener) => {
          socket.off(ev, listener)
        })
      })

      listenersMap = {}
      anyListenerMap = []
    },
  }
}

export const getVolumeDeProjetos = (lang: string) => [
  { label: lang === 'pt-br' ? '1 a 5' : '1 to 5', value: '1 a 5' },
  { label: lang === 'pt-br' ? '6 a 10' : '6 to 10', value: '6 a 10' },
  { label: lang === 'pt-br' ? '11 a 25' : '11 to 25', value: '11 a 25' },
  {
    label: lang === 'pt-br' ? 'Acima de 25' : 'More  than 25',
    value: 'Acima de 25',
  },
]

export const getHowDidYouDinfUs = () => [
  { label: 'Email', value: 'Email' },
  { label: 'Referral', value: 'Referral' },
  { label: 'LinkedIn', value: 'LinkedIn' },
  { label: 'Instagram', value: 'Instagram' },
  { label: 'Facebook', value: 'Facebook' },
  { label: 'Google', value: 'Google' },
]

export const getValorMedioProjeto = (lang: string) => {
  let valorMedio = [
    {
      label: lang === 'pt-br' ? 'Até 30 mil' : 'Up to 30,000',
      value: 'Até 30 mil',
    },
    {
      label: lang === 'pt-br' ? 'Entre 30 e 50 mil' : '30,000 to 50,000',
      value: 'Entre 30 e 50 mil',
    },
    {
      label: lang === 'pt-br' ? 'Entre 50 e 100 mil' : '50,000 to 100,000',
      value: 'Entre 50 e 100 mil',
    },
    {
      label: lang === 'pt-br' ? 'Acima de 100 mil' : 'More than 100,000',
      value: 'Acima de 100 mil',
    },
  ]
  if (lang === 'en') {
    valorMedio = [
      {
        label: '5,000 to 10,000',
        value: 'Entre 5 e 10 mil',
      },
      {
        label: '10,000 to 15,000',
        value: 'Entre 10 e 15 mil',
      },
      {
        label: '15,000 to 20,000',
        value: 'Entre 15 e 20 mil',
      },
      {
        label: '20,000 to 30,000',
        value: 'Entre 20 e 30 mil',
      },
      {
        label: '30,000 to 50,000',
        value: 'Entre 30 e 50 mil',
      },
      {
        label: '50,000 to 100,000',
        value: 'Entre 50 e 100 mil',
      },
      {
        label: 'More than 100,000',
        value: 'Acima de 100 mil',
      },
    ]
  }
  return valorMedio
}

export const getPorcentagemProjetoFinanciado = (lang: string) => [
  { label: lang === 'pt-br' ? 'Até 20%' : 'Up to 20%', value: 'Até 20%' },
  {
    label: lang === 'pt-br' ? '20% a 50%' : '20% to 50%',
    value: '20% a 50%',
  },
  {
    label: lang === 'pt-br' ? 'Acima de 50%' : 'More than 50%',
    value: 'Acima de 50%',
  },
]

export const arrayMax = (arr: number[]) => {
  let len = arr.length,
    max = -Infinity
  while (len--) {
    if (arr[len] > max) {
      max = arr[len]
    }
  }
  return max
}

export const fetchRegionsOptions: AsyncLoadOptions<string> = async (
  search,
  _options,
  { page } = { page: 1 }
) => {
  const params = new URLSearchParams()
  params.set('limit', '10')
  params.set('page', String(page))
  params.set(
    'filter',
    JSON.stringify({ label: { startsWith: search, mode: 'insensitive' } })
  )

  return fetch
    .portal<Region[]>(`/region?${params}`)
    .then(({ data: regions }) => ({
      options: regions.map((region) => ({
        label: region.label,
        value: region.id,
      })),
      additional: { page: page + 1 },
      hasMore: regions.length === 10,
    }))
}

export const fetchLoanTypeOptions: AsyncLoadOptions<string> = async (
  search,
  _options,
  { page } = { page: 1 }
) => {
  const params = new URLSearchParams()
  params.set('limit', '10')
  params.set('page', String(page))
  params.set(
    'filter',
    JSON.stringify({ label: { startsWith: search, mode: 'insensitive' } })
  )

  return fetchLoanTypes({ params }).then((loanTypes) => ({
    options: loanTypes.map((loanType) => ({
      label: loanType.label,
      value: loanType.id,
    })),
    additional: { page: page + 1 },
    hasMore: loanTypes.length === 10,
  }))
}

export const fetchProjectTypesOptions: AsyncLoadOptions<string> = async (
  search,
  _options,
  { page } = { page: 1 }
) => {
  const params = new URLSearchParams()
  params.set('limit', '10')
  params.set('page', String(page))
  params.set(
    'filter',
    JSON.stringify({ title: { startsWith: search, mode: 'insensitive' } })
  )

  return fetchProjectTypes({ params }).then((projectTypes) => ({
    options: projectTypes.map((projectType) => ({
      label: projectType.title,
      value: projectType.id,
    })),
    additional: { page: page + 1 },
    hasMore: projectTypes.length === 10,
  }))
}

export const fetchFinanciersOptions: AsyncLoadOptions<string> = async (
  search,
  _options,
  { page } = { page: 1 }
) => {
  const params = new URLSearchParams()
  params.set('limit', '10')
  params.set('page', String(page))
  params.set('sort', 'razaoSocial')
  params.set('order', 'asc')

  if (search) {
    params.set(
      'filter',
      JSON.stringify(
        makePrismaWhere(search, {
          OR: ['razaoSocial'],
        })
      )
    )
  }
  return await fetchFinanciers({ params, page }).then(({ fundings }) => ({
    options: fundings.map((funding) => ({
      label:
        funding.razaoSocial ||
        funding.nomeFantasia ||
        funding.apelido ||
        funding.cnpj ||
        'N/A',
      value: funding.id,
    })),
    hasMore: fundings.length === 10,
    additional: { page: page + 1 },
  }))
}
